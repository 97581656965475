import { BackEnd, Carrer, FrontEnd, Institutional, Technology } from './icons';

export const categoriesList = [
  {
    name: 'Carreira',
    slug: 'carreira',
    color: '#0000FF',
    icon: <Carrer />,
  },
  {
    name: 'Front-End',
    slug: 'front-end',
    color: '#FA463C',
    icon: <FrontEnd />,
  },
  {
    name: 'Back-End',
    slug: 'back-end',
    color: '#FA8782',
    icon: <BackEnd />,
  },
  {
    name: 'Institucional',
    slug: 'institucional',
    color: '#0094FF',
    icon: <Institutional />,
  },
  {
    name: 'Tecnologia',
    slug: 'tecnologia',
    color: '#000000',
    icon: <Technology />,
  },
];
