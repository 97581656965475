import { BlogPost } from '@/domain/entities/post';
import { translateCategoryById } from '@/helpers/categories';
import CardSkeleton from '@/components/CardSkeleton';

import { CardContainer, Category, Content, Header, StyledLink } from './styles';

type PostCardProps = {
  post: BlogPost;
  showCategory: boolean;
  isLoading: boolean;
};

export default function PostCard({ post, showCategory = false, isLoading = false }: PostCardProps) {
  const category = translateCategoryById(post.category);
  return (
    <>
      {!isLoading ? (
        <CardContainer>
          <StyledLink href={`/${post.slug}`}>
            <Header image={post.media}>
              {showCategory ? <Category color={category.color}>{category.name}</Category> : null}
            </Header>
            <Content className="bg-content">
              <h3>{post.title}</h3>
              <p>{post.readingTime} min de leitura</p>
            </Content>
          </StyledLink>
        </CardContainer>
      ) : (
        <CardSkeleton />
      )}
    </>
  );
}
