import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import logo from '@/images/logo.webp';
import menuIcon from '@/images/menu-icon.png';

import { Cta, LogoWrapper, MenuItem, MenuList, MobileBtn, StyledHeader, StyledNav } from './styles';

export default function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function toogleMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <StyledHeader>
      <StyledNav>
        <LogoWrapper>
          <a rel="noreferrer" href="https://kenzie.com.br">
            <Image src={logo} alt="Kenzie Academy" width="222" height="55" />
          </a>

          <MobileBtn onClick={toogleMenu}>
            <Image src={menuIcon} alt="Ícone Menu" width="32" height="32" />
          </MobileBtn>
        </LogoWrapper>
        <MenuList className={isOpen ? 'open' : 'close'}>
          <MenuItem>
            <a rel="noreferrer" href={`https://kenzie.com.br/quem-somos`}>
              <div>Quem somos</div>
            </a>
          </MenuItem>
          <MenuItem>
            <a rel="noreferrer" href={`https://kenzie.com.br/curriculo`}>
              <div>Formação</div>
            </a>
          </MenuItem>
          <MenuItem>
            <a rel="noreferrer" href={`https://kenzie.com.br/pagamento`}>
              <div>Pagamento</div>
            </a>
          </MenuItem>
          <MenuItem>
            <a rel="noreferrer" href={`https://kenzie.com.br/parceiras`}>
              <div>Para empresas</div>
            </a>
          </MenuItem>
          <MenuItem>
            <a rel="noreferrer" href={`https://kenzie.com.br/faq`}>
              <div>Dúvidas</div>
            </a>
          </MenuItem>

          <MenuItem>
            <Link href={`/`} legacyBehavior>
              <a rel="noreferrer">
                <div className="active">Blog</div>
              </a>
            </Link>
          </MenuItem>
          <MenuItem>
            <a rel="noreferrer" href="https://kenzie.gupy.io/" target="_blank">
              <div>Trabalhe conosco</div>
            </a>
          </MenuItem>
        </MenuList>
        <Cta href={`https://inscricao.kenzie.com.br`} rel="noreferrer">
          Quero ser programador(a)
          <svg width="56" height="16" viewBox="0 0 56 16" fill="none">
            <path
              d="M55.2765 8.42196C55.667 8.03143 55.667 7.39827 55.2765 7.00774L48.9125 0.643781C48.522 0.253257 47.8888 0.253257 47.4983 0.643781C47.1078 1.03431 47.1078 1.66747 47.4983 2.05799L53.1551 7.71485L47.4983 13.3717C47.1078 13.7622 47.1078 14.3954 47.4983 14.7859C47.8888 15.1764 48.522 15.1764 48.9125 14.7859L55.2765 8.42196ZM0.388794 8.71484L54.5694 8.71485L54.5694 6.71485L0.388794 6.71484L0.388794 8.71484Z"
              fill="white"
            />
          </svg>
        </Cta>
      </StyledNav>
    </StyledHeader>
  );
}
