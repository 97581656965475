import styled from 'styled-components';

type CardProps = {
  background: string;
};

export const Background = styled.div`
  background: var(--highlight-blue);
`;

export const StyledSection = styled.section`
  padding: 2.5rem 0;
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.25rem 0;
    max-width: 1170px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Card = styled.li<CardProps>`
  border-radius: 20px;
  border: 1px solid var(--text-color-secondary);
  transition: background, box-shadow, border-color 0.3s ease-in-out;

  &:hover {
    border-color: ${({ background }) => (background ? background : 'var(--highlight-blue)')};
    background: ${({ background }) => (background ? background : 'var(--highlight-blue)')};
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.45);
  }

  a {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.25rem 0.75rem 1.5rem;
  }

  span {
    display: block;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--text-color-secondary);
  }

  @media (min-width: 1024px) {
    height: 12.75rem;
    width: 10.188rem;

    a {
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
  }
`;

export const CardIcon = styled.div`
  margin-right: 1.25rem;

  @media (min-width: 1024px) {
    margin-right: 0;

    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export const ArrowContainer = styled.div`
  margin-left: auto;
`;
