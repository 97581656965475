import Link from 'next/link';
import { useMediaQuery } from '@mui/material';
import Title from '@/components/Title';

import { Arrow } from './icons';
import { categoriesList } from './list';
import { ArrowContainer, Background, Card, CardIcon, List, StyledSection } from './styles';

export default function CategoriesSection() {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  return (
    <Background>
      <StyledSection>
        <Title color="#fff">ASSUNTOS MAIS RELEVANTES:</Title>
        <List>
          {categoriesList.map(category => (
            <Card key={category.name} background={category.color}>
              <Link href={`/categorias/${category.slug}`}>
                <CardIcon>{category.icon}</CardIcon>
                <span>{category.name}</span>
                {!isDesktop ? (
                  <ArrowContainer>
                    <Arrow />
                  </ArrowContainer>
                ) : null}
              </Link>
            </Card>
          ))}
        </List>
      </StyledSection>
    </Background>
  );
}
