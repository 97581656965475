import { CategoryId } from '@/domain/entities/post';

export type CategorySlug =
  | 'back-end'
  | 'carreira'
  | 'css'
  | 'cultura-tech'
  | 'front-end'
  | 'institucional'
  | 'mercado-de-trabalho'
  | 'programacao'
  | 'html'
  | 'javascript'
  | 'tecnologia'
  | 'python'
  | 'uncategorized';

export const categories = {
  1379: {
    name: 'Back-End',
    color: '#FA8782',
  },
  1362: {
    name: 'Carreira',
    color: '#0000ff',
  },
  1382: {
    name: 'CSS',
    color: '#0000ff',
  },
  1363: {
    name: 'Cultura Tech',
    color: '#750088',
  },
  1378: {
    name: 'Front-End',
    color: '#FA463C',
  },
  1364: {
    name: 'Institucional',
    color: '#0094FF',
  },
  1366: {
    name: 'Mercado de Trabalho',
    color: '#B20000',
  },
  1381: {
    name: 'Programação',
    color: '#ff1493',
  },
  1383: {
    name: 'HTML',
    color: '#0000ff',
  },
  1384: {
    name: 'Javascript',
    color: '#0000ff',
  },
  1367: {
    name: 'Tecnologia',
    color: '#000',
  },
  1385: {
    name: 'Python',
    color: '#0000ff',
  },
  1: {
    name: 'Uncategorized',
    color: '#0000ff',
  },
};

export const categoriesSlugs = [
  'back-end',
  'carreira',
  'css',
  'cultura-tech',
  'front-end',
  'institucional',
  'mercado-de-trabalho',
  'programacao',
  'html',
  'javascript',
  'tecnologia',
  'python',
  'uncategorized',
];

export const categoriesIds: { [key: string]: CategoryId } = {
  'back-end': 1379,
  carreira: 1362,
  css: 1382,
  'cultura-tech': 1363,
  'front-end': 1378,
  institucional: 1364,
  'mercado-de-trabalho': 1366,
  programacao: 1381,
  html: 1383,
  javascript: 1384,
  tecnologia: 1367,
  python: 1385,
  uncategorized: 1,
};

export function translateCategoryById(id: CategoryId) {
  return categories[id];
}
