import { Box, Skeleton } from '@mui/material';

export default function CardSkeleton() {
  return (
    <li style={{ flexGrow: '1' }}>
      <Box>
        <Skeleton variant="rectangular" width={356} height={246} />

        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    </li>
  );
}
