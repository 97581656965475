import Link from 'next/link';
import styled from 'styled-components';

type HeaderProps = {
  image: string;
};

type CategoryProps = {
  color: string;
};

export const CardContainer = styled.li`
  height: 20rem;
  width: 21.875rem;
  border-radius: 1rem;
  transition: box-shadow 0.3s ease-in-out;
  flex-grow: 1;

  &:hover {
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.2);

    .bg-content {
      background: var(--text-color-secondary);
    }
  }

  @media (min-width: 400px) {
    min-width: 23.875rem;
  }

  @media (min-width: 1100px) {
    max-width: 22.313rem;
  }
  @media (min-width: 1400px) {
    max-width: 25.513rem;
  }
`;

export const StyledLink = styled(Link)`
  height: 100%; ;
`;

export const Header = styled.div<HeaderProps>`
  height: 50%;
  background: url(${({ image }) => (image ? image : '')}) no-repeat center;
  background-size: cover;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  position: relative;
`;

export const Content = styled.div`
  height: 50%;
  background: #f5f5f5;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: background 0.3s ease-in-out;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    margin-bottom: 0.8rem;
  }

  p {
    margin-top: auto;
  }

  @media (min-width: 1024px) {
    padding-top: 2.5rem;
  }
`;

export const Category = styled.div<CategoryProps>`
  background: ${({ color }) => (color ? color : 'var(--highlight-blue)')};
  color: var(--text-color-secondary);
  padding: 0.5rem 2rem;
  position: absolute;
  bottom: -15px;
  font-size: 1rem;
`;
