import styled from 'styled-components';

type TitleProps = {
  color?: string;
};

export const StyledTitle = styled.h2<TitleProps>`
  font-size: 1.625rem;
  font-weight: 800;
  color: ${({ color }) => (color ? color : 'var(--highlight-blue)')};
  margin-bottom: 2.5rem;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    margin-bottom: 4.5rem;
    font-size: 3rem;
  }
`;
